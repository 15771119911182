export default {
  default: {
    name: "Evento Desconhecido",
    title: "Hmmmm, parece que o convite não está mais ativo ou não existe!",
    description: "",
    image: "...",
    active: false
  },
  "": {
    name: "Inauguração do Escritório",
    active: false,
    inviteImage: "/inauguracao-convite.png",
    confirmImage: "/inauguracao-fim.png",
    title:
      "Queremos convidar você para um <strong>evento especial da Layers,</strong> pois você faz parte da nossa história!"
  },
  store22: {
    name: "Lançamento Presencial da Layers Store",
    active: true,
    title:
      "Queremos convidar você para um <strong>evento especial da Layers,</strong> pois você faz parte da nossa história!",
    inviteImage: "/store22-convite.png",
    confirmImage: "/store22-fim.png",

    address: "Layers - Rua Pará, 139 - Sala 501 - São Caetano do Sul - SP",
    startDate: "2022-11-10T17:00:00+00:00",
    endDate: "2022-11-10T21:00:00+00:00"
  },
  "bea-ivan": {
    name: "Deu Match: Festa Fantasia - Bia & Ivan",
    title:
      "Aqui é a <strong>Bea e o Ivan</strong>. Fazemos aniversário no mesmo mês e queremos convidar você para nossa <strong>festa de aniversário</strong>",
    address: "Layers - Rua Pará, 139 - Sala 501 - São Caetano do Sul - SP",

    inviteImage: "/bivan-inicio.png",
    confirmImage: "/bivan-fim.png",
    active: true,
    startDate: "2023-10-21T22:00:00+00:00",
    endDate: "2023-10-21T26:00:00+00:00"
  }
};
