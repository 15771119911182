<template>
  <section :class="{ completed }">
    <Transition name="fade" mode="out-in" appear>
      <h1 v-if="sending">Enviando confirmação...</h1>
      <h1 v-else-if="failedMessage">{{ failedMessage }}</h1>
      <h1 v-else-if="!completed">Mas antes...</h1>
      <h1 v-else>Prontinho!</h1>
    </Transition>

    <Transition name="fade" mode="out-in" appear>
      <p v-if="!completed && trashes >= 5 && strokes == 0">
        <strong>Ótimo kkk</strong> Vamos fazer tudo do zero novamente?
      </p>
      <p v-else-if="!completed && trashes >= 3">
        <strong>Detalhista</strong> você...
      </p>
      <p v-else-if="!completed && trashes >= 1">
        Nem sempre a gente <strong>acerta</strong> de primeira.
      </p>
      <p v-else-if="!completed && buttonReady">
        Aí sim! Pode adicionar quantos <strong>detalhes</strong> quiser, e
        depois é só <strong>seguir em frente</strong> :)
      </p>
      <p v-else-if="!completed && strokes > 1">
        Estamos <strong>quaaase</strong> lá...
      </p>
      <p v-else-if="!completed && strokes > 0">
        Começar é o <strong>mais difícil</strong> mesmo, basta
        <strong>continuar</strong> agora!
      </p>
      <p v-else-if="!completed">
        Use suas habilidades artísticas para <strong>desenhar</strong>,
        <strong>enfirular</strong> e <strong>personalizar</strong> nossa abóbora
        do match 🎃
        <!-- <strong>Layers!</strong> -->
        <!-- <strong>Festa!</strong> -->
      </p>
      <p v-else>
        Sua presença foi confirmada ;)<br /><strong
          class="link"
          v-on:click="newDraw()"
          >Quero fazer outro desenho!</strong
        >
      </p>
    </Transition>

    <Transition name="deck" mode="out-in" appear>
      <div v-if="completed" class="canvas-group" key="first">
        <img
          class="finally"
          :class="{ loaded }"
          @load="loaded = true"
          :src="event.confirmImage"
        />
      </div>
      <div
        v-else
        class="canvas-group"
        :class="{ shake: sending && !completed }"
      >
        <img class="background" draggable="false" src="../assets/abobora.png" />
        <VueDrawingCanvas
          ref="VueCanvasDrawing"
          :width="width"
          :height="width"
          :color="selectedColor"
          :lineWidth="selectedThickness"
          :lock="sending"
          @update:image="imageUpdated"
          lineCap="round"
          lineJoin="round"
        />
      </div>
    </Transition>

    <Transition name="fade" mode="out-in" appear>
      <div class="tools" v-if="!completed && !sending">
        <div class="tool-group">
          <div
            v-for="color in colors"
            :key="color"
            class="tool-item"
            :class="{ selected: color == selectedColor }"
            @click="selectedColor = color"
          >
            <div class="tool-color" :style="{ '--color': color }" />
          </div>
        </div>
        <div class="tool-group">
          <div
            v-for="thick in thickness"
            :key="thick"
            class="tool-item"
            :class="{ selected: thick == selectedThickness }"
            @click="selectedThickness = thick"
          >
            <div
              class="tool-thickness"
              :style="{ '--thickness': thick + 'px' }"
            />
          </div>
        </div>
        <div class="tool-group">
          <div
            class="tool-item"
            :class="{ disabled: !canUndo }"
            @click="$refs.VueCanvasDrawing.undo()"
          >
            <IconUndo />
          </div>
          <div
            class="tool-item"
            :class="{ disabled: !canRedo }"
            @click="$refs.VueCanvasDrawing.redo()"
          >
            <IconUndo style="transform: scaleX(-1)" />
          </div>
        </div>
      </div>
    </Transition>

    <div class="filler" />

    <Transition name="fade" mode="out-in" appear>
      <button
        v-if="!completed"
        @click="submit()"
        class="confirm-button"
        :disabled="!buttonReady"
      >
        {{ buttonMessage }}
      </button>
      <button v-else class="calendar-button" @click="openCalendarGoogle()">
        <img src="../assets/calendar.png" width="20" /> Adicionar na Agenda
        Google
      </button>
    </Transition>
    <Transition name="fade" mode="out-in">
      <button
        v-if="completed"
        class="calendar-button"
        @click="openCalendarMicrosoft()"
      >
        <IconMicrosoft /> Adicionar na Agenda Microsoft
      </button>
    </Transition>
  </section>
</template>

<script>
import VueDrawingCanvas from "vue-drawing-canvas/dist/vue-drawing-canvas.esm";
import Axios from "axios";

import IconUndo from "./IconUndo";
import IconMicrosoft from "./IconMicrosoft";

export default {
  name: "Draw",

  components: {
    VueDrawingCanvas,
    IconUndo,
    IconMicrosoft,
  },

  props: {
    name: String,
    ntid: String,
    event: Object,
  },

  // mounted() {
  //   setTimeout(() => {
  //     this.completed = false;
  //   }, 500);
  // },

  data() {
    const appSize =
      document.querySelector("#app").getBoundingClientRect().width - 48 - 4;

    // const colors = ["#40CAC2", "#FBA0B1", "#F5B845", "#405261"];
    const colors = ["#E9427B", "#ED6F62", "#32DA43", "#27AFFF", "#1D2B36"];
    const thickness = [5, 12, 20];
    return {
      image: "",
      images: null,
      width: appSize,
      strokes: 0,
      trashes: 0,
      colors,
      selectedColor: colors[0],
      thickness,
      selectedThickness: 12,
      failedMessage: "",
      completed: false,
      sending: false,
      startedAt: Date.now(),
      loaded: false,
    };
  },

  computed: {
    buttonMessage() {
      if (this.sending) return "Aguarde...";
      return (
        {
          0: "Toque na tela e solte a criatividade!",
          1: "Foi um bom começo, vamos além?",
          2: "Desenhe mais um pouquinho...",
        }[this.strokes] || "Confirmar participação (finalmente)"
      );
    },
    buttonReady() {
      return !this.sending && this.strokes > 2;
    },

    canUndo() {
      return this.strokes > 0;
    },

    canRedo() {
      return this.trashes > 0;
    },
  },

  methods: {
    imageUpdated(image) {
      this.image = image;
      this.strokes = this.$refs.VueCanvasDrawing.images.length;
      this.trashes = this.$refs.VueCanvasDrawing.trash.length;
    },

    async submit() {
      if (this.sending) return;

      try {
        navigator.vibrate(200);
      } catch (e) {
        console.log("vibrate unavailable");
      }

      try {
        this.failedMessage = null;
        this.sending = true;
        await Axios.post("https://eotd84jdu84g2ab.m.pipedream.net/confirm", {
          eventName: this.event.name,
          name: this.name,
          image: this.image,
          ntid: this.ntid,
          duration: Math.round((Date.now() - this.startedAt) / 1000),
        });
        setTimeout(() => {
          this.completed = true;

          try {
            navigator.vibrate(1000);
          } catch (e) {
            console.log("vibrate unavailable");
          }
        }, 40);
      } catch (e) {
        this.failedMessage = "Ocorreu uma falha. Tente novamente";
      } finally {
        this.sending = false;
      }
    },

    openCalendarMicrosoft() {
      // https://www.labnol.org/calendar/
      const url = new URL(
        "https://outlook.live.com/calendar/0/deeplink/compose"
      );
      url.searchParams.set("allday", "false");
      url.searchParams.set("startdt", this.event.startDate); //'2022-09-30T19:00:00+00:00')
      url.searchParams.set("enddt", this.event.endDate); //'2022-09-30T23:00:00+00:00')
      url.searchParams.set("subject", this.event.name); //'Inauguração do escritório da Layers')
      url.searchParams.set("location", this.event.address); //'Layers - Rua Pará, 139 - Sala 501 - São Caetano do Sul - SP')
      url.searchParams.set("path", "/calendar/action/compose");
      url.searchParams.set("rru", "addevent");
      window.open(
        // "https://outlook.live.com/calendar/0/deeplink/compose?allday=false&enddt=2022-09-30T23%3A00%3A00%2B00%3A00&location=Layers%20-%20Rua%20Par%C3%A1%2C%20139%20-%20Sala%20501%20-%20S%C3%A3o%20Caetano%20do%20Sul%20-%20SP&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-09-30T19%3A00%3A00%2B00%3A00&subject=Inaugura%C3%A7%C3%A3o%20do%20escrit%C3%B3rio%20da%20Layers",
        url.toString(),
        "_blank"
      );
      this.track("Convidado clicou no botão de adicionar à agenda Microsoft");
    },

    openCalendarGoogle() {
      const url = new URL("https://calendar.google.com/calendar/render");
      url.searchParams.set("action", "TEMPLATE");
      const toGoogleDate = (date) =>
        date.replace(/[-:]/g, "").replace(/\+.+/, "") + "Z";
      url.searchParams.set("location", this.event.address);
      url.searchParams.set(
        "dates",
        `${toGoogleDate(this.event.startDate)}/${toGoogleDate(
          this.event.endDate
        )}`
      );
      url.searchParams.set("text", this.event.name);

      window.open(
        url.toString(),
        // "https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20220930T190000Z%2F20220930T230000Z&location=Layers%20-%20Rua%20Par%C3%A1%2C%20139%20-%20Sala%20501%20-%20S%C3%A3o%20Caetano%20do%20Sul%20-%20SP&text=Inaugura%C3%A7%C3%A3o%20do%20escrit%C3%B3rio%20da%20Layers",
        "_blank"
      );
      this.track("Convidado clicou no botão de adicionar à agenda Google");
    },

    track(event) {
      Axios.post("https://eotd84jdu84g2ab.m.pipedream.net/track", {
        name: this.name,
        event,
        eventName: this.event.name,
      });
      console.log("🔔 track", event);
    },

    newDraw() {
      this.completed = false;
      this.startedAt = Date.now();
      this.track("Começou um novo desenho depois de terminar o anterior");
    },
  },
};
</script>

<style scoped>
section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 12px;

  perspective: 1000px;
}

section > p {
  min-height: 3em;
}

canvas {
  cursor: crosshair;
}

.confirm-button {
  align-self: stretch;
  --color: #00B8AD;
}

.calendar-button {
  align-self: stretch;
  --color: white;
  color: #434E5B;
  background: white;
}
.calendar-button > svg,
.calendar-button > img {
  width: 20px;
  height: 20px;
}

.canvas-group {
  box-sizing: content-box;
  border: 2px solid #E8ECEF;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  align-self: stretch;
  aspect-ratio: 1;

  /* transform-origin: center 1500px; */
  transition: transform 0.5s ease, opacity 1s;
}

.canvas-group > img.finally {
  object-fit: fill;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.6s;
}

.canvas-group > img.finally.loaded {
  opacity: 1;
}

.background {
  position: absolute;
  pointer-events: none;
  opacity: 0.1;
  width: 360px;
  height: 360px;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.tools {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  align-self: stretch;

  transition: opacity 0.5s;
}

.tool-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 0px;

  flex: 1 0 auto;

  border: 1px solid #E8ECEF;
  border-radius: 4px;
  overflow: hidden;
}

.tool-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  height: 54px;
  /* min-width: 40px; */
  background: white;

  flex: 1 0 auto;

  cursor: pointer;
}

.tool-item.disabled {
  opacity: 0.5;
}

.tool-item.selected {
  background: #EEF1F5;
}

.tool-item > .tool-color {
  background: var(--color);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid white;
}


.tool-item.selected > .tool-color {
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
}


.tool-item > .tool-thickness {
  background: black;
  width: 24px;
  height: var(--thickness);
  border-radius: calc(var(--thickness) / 2);
}


/* section.completed > .canvas-group {
  transform: rotateZ(35deg);
  opacity: 0;
  pointer-events: none;
} */

section.completed > .tools {
  opacity: 0;
  pointer-events: none;
}
</style>