<template>
  <section>
    <h1 @click="$emit('next')">Link inválido</h1>
    <p>
      <strong>Quem é você?</strong> <br />O link que acessou parece ser
      inválido... Fale com alguém da Layers para corrigir isso.
    </p>
    <!-- <img class="convite" src="../assets/convite.png" alt="Imagem do convite"/> -->
    <div class="filler" />
    <!-- <button @click="next()" class="confirm-button" disabled>
      Confirmar presença (desabilitado)
    </button> -->
  </section>
</template>

<script>
export default {
  name: "Home",
  props: {
    name: String,
  },
};
</script>

<style scoped>
section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 12px;
}

.convite {
  flex: 0 0 auto;
  width: 100%;

  border-radius: 8px;
  box-sizing: border-box;
  border: 2px solid #E8ECEF;
}

.confirm-button {
  align-self: stretch;
  --color: var(--primary-color);
}
</style>