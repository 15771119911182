<template>
  <Transition name="fade" mode="out-in">
    <Home
      key="home"
      v-if="step == 'home'"
      :name="simpleName"
      :event="event"
      @next="step = 'draw'"
    />
    <Draw
      key="draw"
      v-else-if="step == 'draw'"
      :name="name"
      :event="event"
      :ntid="ntid"
      @previous="step = 'home'"
    />
    <Unknown key="unknown" v-else />
  </Transition>
</template>

<script>
import Home from "./components/Home";
import Draw from "./components/Draw";
import Unknown from "./components/Unknown";
import Axios from "axios";
import Events from "./Events";

export default {
  name: "App",

  data() {
    const nameInUrl = new URLSearchParams(window.location.search).get("name");
    const name = (nameInUrl || " ??? ").replace(/[_-]/g, " ");
    const simpleName = name.split(" ")[0] || "";
    const ntid =
      new URLSearchParams(window.location.search).get("ntid") || undefined;

    const eventId = window.location.pathname.substring(1);

    const event = Events[eventId];
    const unknown = !event || !nameInUrl;
    // console.log("Using event: ", event);
    return {
      step: unknown ? "unknown" : "home",
      name,
      ntid,
      simpleName,
      event,
    };
  },

  components: {
    Home,
    Draw,
    Unknown,
  },

  mounted() {
    window.onbeforeunload = () => {
      if (window.location.href.includes("netlify"))
        if (this.step === "draw") return "";
    };
  },

  async created() {
    // Send event to analytics
    const lastAccessAt =
      parseInt(localStorage.getItem("lastAccessAt"), 10) || 0;

    if (Date.now() - lastAccessAt > 1000 * 60 * 10) {
      localStorage.setItem("lastAccessAt", Date.now());
      try {
        await Axios.post("https://eotd84jdu84g2ab.m.pipedream.net/track", {
          name: this.name,
          eventName: (this.event || {}).name,
        });
        // console.log("🔔 track", "started");
      } catch (e) {
        console.log("failed to track", e);
      }
    }
  },
};
</script>

<style>
#app {
  display: flex;
  flex-direction: column;

  padding: 24px;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;

  /* min-height: 100vh; */
  height: 100%;
  width: min(450px, 100vw);
  background-color: #fff;

  overflow-y: auto;
}

#app > * {
  flex: 1 0 auto;
  overflow: hidden;
}
</style>

<style>
/* Global Styles */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

:root {
  --pink-dark: #FBA0B1;
  --gray-90: #1D2B36;
  --aqua: #00B8AD;

  /* --primary-color: #00B8AD;
  --secondary-color: var(--pink-dark); */
  --primary-color: #EC6469;
  --secondary-color: #EC6469;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
}

h1 {
  margin: 0;
  margin-top: 12px;
  flex: 0 1 auto;
  font-family: "Montserrat", "Nunito", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 110%;
  color: var(--secondary-color);
}

p {
  margin: 0;
  flex: 0 0 auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5em;

  color: var(--gray-90);
}

.link {
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
}

.filler {
  flex: 1 1 auto;
}

button {
  --color: #0366ee;
  -webkit-appearance: none;
  border-radius: 4px;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  text-transform: none;
  padding: 12px 12px;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  line-height: 1;

  box-sizing: border-box;
  border: 2px solid rgba(0, 0, 0, 0.05);
  background: var(--color);
  color: #ffffff;

  border-radius: 4px;
  background: linear-gradient(90deg, #E9427B 3.04%, #ED6F62 100%);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

button[disabled] {
  --color: var(--gray-90) !important;
  opacity: 0.5;
}

@media (min-height: 740px) {
  button {
    padding: 18px 12px;
  }
  h1 {
    margin-top: 24px;
  }
}

/* Animations */
/* rules that target nested elements */
.fade-enter-active,
.fade-leave-active {
  opacity: 0;
  transition: all 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

/* Deck slide */
.deck-enter-active,
.deck-leave-active {
  transition: opacity 1s, transform 1.5s ease !important;
  transform-origin: center 1500px;
}

.deck-enter-from {
  opacity: 0;
  transform: rotateZ(-25deg);
}
.deck-leave-to {
  opacity: 0;
  transform: rotateZ(25deg);
}

.deck-enter-to,
.deck-leave-from {
  opacity: 1;
  transform: none;
}

/* Shake */
.shake {
  animation: shake 5s infinite cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
@keyframes shake {
  2%,
  18% {
    transform: translate3d(-1px, 0, 0);
  }

  4%,
  16% {
    transform: translate3d(2px, 0, 0);
  }

  6%,
  10%,
  14% {
    transform: translate3d(-4px, 0, 0);
  }

  8%,
  12% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
