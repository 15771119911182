<template>
  <section>
    <h1 @click="$emit('next')">Hey, {{ name }}!</h1>
    <p v-html="event.title" />
    <button @click="next()" class="confirm-button">
      Confirmar presença (próximo passo)
    </button>
    <!-- <div class="filler" /> -->
    <img
      class="convite"
      :class="{ loaded }"
      :src="event.inviteImage"
      @load="loaded = true"
      alt="Imagem do convite"
    />
  </section>
</template>

<script>
export default {
  name: "Home",
  props: {
    name: String,
    event: Object,
  },

  data() {
    return {
      loaded: false,
    };
  },

  methods: {
    next() {
      this.$emit("next");
      try {
        navigator.vibrate(200);
      } catch (e) {
        console.log("vibrate unavailable");
      }
    },
  },
};
</script>

<style scoped>
section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 12px;
}

.convite {
  flex: 0 0 auto;
  width: 100%;

  border-radius: 8px;
  box-sizing: border-box;
  border: 2px solid #E8ECEF;
  aspect-ratio: 1;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.6s;
}

.convite.loaded {
  opacity: 1;
}

.confirm-button {
  align-self: stretch;
  --color: #00B8AD;
}
</style>